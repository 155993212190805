import {action, createStore} from "easy-peasy";
import 'easy-peasy/map-set-support';
import generateUsername from "trendy-username";

function generateFancyUsername() {
    const random = Math.round(Math.random())
    if (random < 1) {
        return generateUsername(1, "female", true)[0];
    } else {
        return  generateUsername(1, "male", true)[0];
    }
}

const store = createStore({
    messages: [],
    typers: {},
    resetTypers: action((state) => { state.typers = {}}),
    addMessage: action((state, payload) => {
        if (typeof payload === "string") {
            payload = JSON.parse(payload)
        }
        if (payload.MessageId) {
            const topic = payload.Subject
            let msg = JSON.parse(payload.Message)
            if (topic === "NEW COMMENT") {
                const id = msg.head
                let speaker = state.typers[id]
                if (speaker === undefined) {
                    speaker = generateFancyUsername()
                }
                const messageOutputObject = {
                    comment: msg.comment,
                    speaker: speaker,
                    id: payload.MessageId
                }
                state.messages.push(messageOutputObject)
                if (id in state.typers) {
                    delete state.typers[id]
                }
            } else if (topic === "NOW THINKING") {
                state.typers[msg.head] = generateFancyUsername()
            } else {
                console.log("Topic does not match")
            }
        } else {
            // own message
            if (payload.comment) {
                state.messages.push(payload)
            }
        }
    })
})

export default store;