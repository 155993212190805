import './App.css';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    Sidebar,
    Search,
    ConversationList,
    Conversation,
    Avatar,
    ConversationHeader,
    VoiceCallButton,
    InfoButton,
    VideoCallButton,
    TypingIndicator,
    AvatarGroup,
    MessageSeparator
} from '@chatscope/chat-ui-kit-react';
import {useEffect, useState} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import {v4 as uuidv4} from 'uuid';
import Linkify from 'react-linkify';
import {Authenticator} from '@aws-amplify/ui-react';
import Amplify, {Auth} from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import {openWebSocket} from "./socket";

Amplify.configure(awsconfig);

// amount of pictures available in the random picture folder
const numberOfPictures = 100
// simple shuffling of numbers to make mapping random
const shuffledNumbers = length => Array.from(Array(length).keys()).sort(() => Math.random() - 0.5)
let randomNumbers = []

function AppContext() {
    const messages = useStoreState((state) => state.messages)
    const addMessage = useStoreActions((actions) => actions.addMessage)
    const typings = useStoreState((state) => state.typers)
    const resetTypers = useStoreActions((actions) => actions.resetTypers)
    const [showFBI, setShowFBI] = useState(true);

    function send(html, message) {
        addMessage({comment: message})
    }

    // uses mapping to stored images
    // add numberOfPictures amount if needed
    function getRandomNumber(index) {
        if (index >= randomNumbers.length) {
            randomNumbers = randomNumbers.concat(shuffledNumbers(numberOfPictures))
        }
        return randomNumbers[index]
    }

    // shows current typers
    function getTypers() {
        if (typings === undefined || Object.keys(typings).length === 0) return undefined
        let typers = Object.values(typings)
        if (typers.length === 1) {
            return <TypingIndicator style={{background: 'rgb(241, 241, 241, 0)', marginTop: "1rem"}} content={typers[0] + " is typing"}/>
        } else {
            return <TypingIndicator style={{background: 'rgb(241, 241, 241, 0)'}}
                                    content={typers.join(", ") + " are typing"}/>
        }
    }

    setTimeout(() => setShowFBI(), 1000 * 60 * 30); //TODO: set random?

    const colors = ["pink", "black", "red", "grey", "blue", "lime", "yellow", "cyan", "fuchsia", "silver", "purple", "green", "orange", "beige", ""]
    // copy color array by value
    let availableColors = colors.slice()
    const getColorClass = () => {
        if (availableColors.length === 0) {
            availableColors = colors.slice()
        }
        return availableColors.pop();
    }

    function openImage() {
        window.location = "https://www.tngtech.com"
    }

    useEffect(
        () => {
            Auth.currentSession().then(res=>{
                let jwt = res.getAccessToken().getJwtToken()
                openWebSocket(jwt)
            })
        }, []
    )

    return (
        <div className="App">
            <div style={{
                position: "absolute", left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)', height: "100%",
                width: "100%"
            }}>
                <MainContainer className="app-main-container" responsive>
                    <Sidebar position="left" scrollable={false}>
                        <ConversationHeader>
                            <Avatar src="avatar/you.png" name="You" status="invisible"/>
                            <ConversationHeader.Content userName="You" info="Incognito"/>
                        </ConversationHeader>
                        <Search placeholder="Search..."/>
                        <ConversationList>
                            <Conversation name="ShitTalkers" lastSenderName={
                                messages[messages.length - 1] !== undefined ? messages[messages.length - 1].speaker : "Elliot"
                            }
                                          info={
                                              messages[messages.length - 1] !== undefined ? messages[messages.length - 1].comment : "World Domination!!!"
                                          }
                                          unreadCnt={messages.length} active>
                                <AvatarGroup size="sm">
                                    <Avatar src="avatar/avatar_1.jpg" name="Eliot"/>
                                    <Avatar src="avatar/avatar_2.jpg" name="Akane"/>
                                    <Avatar src="avatar/avatar_3.jpg" name="Joe"/>
                                    <Avatar src="avatar/avatar_4.jpg" name="Zoe"/>
                                </AvatarGroup>
                            </Conversation>
                            <Conversation name="Zoe" lastSenderName="Zoe" info="We need to talk about Kevin">
                                <Avatar src="avatar/avatar_5.jpg" name="Zoe" status="available"/>
                            </Conversation>
                            {!showFBI ? <Conversation name="FBI" lastSenderName="FBI" info="WHERE ARE YOU???">
                                <Avatar src="avatar/fbi.svg" name="FBI" status="available"/>
                            </Conversation> : null}
                        </ConversationList>
                        <div style={{marginLeft: "1em"}} className="tng-logo-web">
                            Developed by
                        </div>
                        <div as="Conversation" className="cs-conversation">
                            <a style={{margin: "auto"}} href="https://www.tngtech.com" className="tng-logo-web">
                                <img style={{height: "2.5em"}} src={"logo.png"} alt={"logo"}/>
                            </a>
                        </div>
                    </Sidebar>
                    <ChatContainer>
                        <ConversationHeader>
                            <AvatarGroup size="sm" style={{
                                width: "50px",
                                height: "68px"
                            }}>
                                <Avatar src="avatar/avatar_1.jpg" name="Eliot"/>
                                <Avatar src="avatar/avatar_2.jpg" name="Akane"/>
                                <Avatar src="avatar/avatar_3.jpg" name="Joe"/>
                                <Avatar src="avatar/avatar_4.jpg" name="Zoe"/>
                            </AvatarGroup>
                            <ConversationHeader.Content userName="ShitTalkers" info="Saving the planet"/>
                            <ConversationHeader.Actions>
                                <VoiceCallButton/>
                                <VideoCallButton/>
                                <InfoButton title="Show info" />
                                <img style={{height: "1.5em", marginLeft: "0.2em"}} className="tng-logo-mobile" src="favicon.ico" alt={"TNG"} onClick={() => openImage()}/>
                            </ConversationHeader.Actions>
                        </ConversationHeader>

                        <MessageList typingIndicator={getTypers()} className="message-list">
                            <MessageSeparator content="Today" as="h2" style={{background: "rgb(241, 241, 241, 0)"}}/>
                            {messages.map((message, index) => {
                                const direction = message.id ? "incoming" : "outgoing"
                                const avtPosition = message.id ? "cl" : "cr"
                                const json = message
                                if (json.comment !== undefined) {
                                    return <Message model={{
                                            sentTime: "just now",
                                            type: "html",
                                            position: "single",
                                            sender: json.speaker,
                                            direction: direction
                                        }}
                                                 avatarPosition={avtPosition}
                                                 key={json.id ? json.id : uuidv4()}
                                                 style={{maxWidth: "60%", marginBottom: "1em"}}
                                                 className="message-input">

                                            <Avatar
                                                src={json.id ? "random_pictures/random_" + getRandomNumber(index) + ".jpg": "avatar/you.png"}/>
                                            <Message.Header sender={json.id ? json.speaker : "Me"} className={getColorClass()}/>
                                            <Message.CustomContent className="">
                                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a target="blank" rel="noopener" href={decoratedHref} key={key}>
                                                        {decoratedText}
                                                    </a>}>
                                                    {json.comment}
                                                </Linkify>
                                            </Message.CustomContent>
                                        </Message>
                                } else {
                                    return ""
                                }
                            })}
                        </MessageList>
                        <MessageInput onSend={send} placeholder="Type message here" className="chat-input"/>
                    </ChatContainer>
                </MainContainer>
            </div>
        </div>
    );
}

export default function App() {
    return (
        <Authenticator
            hideSignUp={true}
        >
            {({ signOut, user }) => (
                <main>

                    <AppContext />
                </main>
            )}
        </Authenticator>
    );
}