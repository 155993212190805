import store from "./store";


export function openWebSocket(token) {
    const socket = new WebSocket("wss://43wgzc4xq3.execute-api.eu-central-1.amazonaws.com/th_notifications_websocket/?token=" + token);
    const addMessage = store.getActions().addMessage
    socket.onopen = function (e) {
        console.log("[open] Connection established");
    };

    socket.onmessage = function (event) {
        addMessage(event.data)
    };

    socket.onclose = function (event) {
        if (event.wasClean) {
            console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
            console.log('[close] Connection died');
        }
    };

    socket.onerror = function (error) {
        console.log(`[error] ${error.message}`);
    };
}